// Open and close mobile nav menu
function openNav() {
    // nav menu and mobileButton's 3 bars
    let navBar = document.getElementById('mobileMenu'),
        bar1 = document.getElementById('bar1'),
        bar2 = document.getElementById('bar2'),
        bar3 = document.getElementById('bar3');

    if (navBar.style.transform !== "translateY(0px)") {
        //opens mobile nav menu and adds X animation to mobileButton
        navBar.style.transform = "translateY(0px)";
        bar1.style.animation = "topX .5s forwards";
        bar2.style.animation = "midX .5s forwards";
        bar3.style.animation = "bottomX .5s forwards";
    } else {
        //closes mobile nav menu and adds hamburger animation to mobileButton
        navBar.style.transform = "translateY(-800px)";
        bar1.style.animation = "topRX .5s both";
        bar2.style.animation = "midRX .5s both";
        bar3.style.animation = "bottomRX .5s both";
    }
}

function moveCategories() {
    if (window.matchMedia("(max-width: 767px)").matches) {
        $('#categories').insertAfter($('#propertySlider'));
    } else {
        $('#propertySlider').insertAfter($('#categories'));

    }
}

window.addEventListener('resize', moveCategories);


$(document).ready(function () {


    moveCategories();


    $('.property__slider').slick({
        dots: true,
        infinite: true,
        speed: 500,
        fade: true,
        cssEase: 'linear'
    });


    $('.slick').slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });

    $('.slick-testimonial').slick({
        arrows: true,
        dots: true,
        responsive: [
            {
                breakpoint: 567,
                settings: {
                    arrows: false
                }
            }
        ]
    });
});
